//COMMENT add comments
import { defineStore } from 'pinia';
import { getDateStamp, getTimeStamp } from '@/helper/date';
import { v4 as uuidv4 } from 'uuid';
import { useAppStore } from './app';
import differenceInDays from 'date-fns/differenceInDays';
import { computed, ref, watch } from 'vue';
import Vue from 'vue';
import axios from 'axios';
import { storeToRefs } from 'pinia';

export const usePrestationStore = defineStore('prestation', () => {
    const prestations = ref(JSON.parse(localStorage.getItem('prestations')) ?? {});
    const currentId = ref(localStorage.getItem('current') ?? null);
    const sendingData = ref(false);

    const { coordinats, settings } = storeToRefs(useAppStore());
    const { showSnackbar } = useAppStore();

    watch(prestations, () => {
        localStorage.setItem('prestations', JSON.stringify(prestations.value));
    }, {
        deep: true
    });

    watch(currentId, () => {
        localStorage.setItem('current', currentId.value);

    });

    const current = computed(() => {
        if (!currentId.value) {
            return null;
        }

        const prestArr = Object.values(prestations.value);

        for (let i = 0; i < prestArr.length; i++) {
            const prests = prestArr[i];
            for (let j = 0; j < prests.length; j++) {
                const prest = prests[j];
                if (currentId.value === prest.id) {
                    return prest;
                }
            }
        }

        return null;
    });

    const sendData = computed(() => {
        return Object
            .values(prestations.value)
            .reduce((sendData, prestOfDay) => {
                sendData.push(...prestOfDay.filter((pres) => !(pres.locked || pres.send)));
                return sendData;
            }, []);
    });

    function addPrestation (task) {
        const uuid = uuidv4();

        const date = new Date();

        const dateStamp = getDateStamp(date);
        const timeStamp = getTimeStamp(date);

        const prestation = {
            id: uuid,
            meta: task.meta,
            str1: (coordinats.value.lat ?? '') + ',',
            str2: (coordinats.value.long ?? '') + ',',
            locked: false,
            send: false,
            date_prestation: dateStamp,
            designation: task.label ?? '',
            heure_debut: timeStamp,
            heure_fin: null,
            user: settings.value.apiUsername
        };

        if (!(dateStamp in prestations.value)) {
            Vue.set(prestations.value, dateStamp, []);
        }

        prestations.value[dateStamp].unshift(prestation);

        currentId.value = uuid;
    }

    async function finishPrestation (data) {
        const currentMeta = current?.value?.meta;
        closeCurrent();

        try {
            if (settings.value.autoUpload) {
                await uploadPrestation();
            }
        } finally {
            if (data.meta !== currentMeta) {
                addPrestation(data);
            }
        }
    }

    function clearOldPrestations () {
        const cDay = getDateStamp(new Date());
        Object.keys(prestations.value).forEach((pDate) => {
            const diff = Math.abs(
                differenceInDays(
                    new Date(cDay), new Date(pDate)
                )
            );
            if (diff > 7) {
                delete prestations.value[pDate];
            }
        });
    }

    function closeCurrent () {
        if (current.value) {
            closePrest(current.value);
        }
    }

    function closePrest (prest) {
        if (!prest.heure_fin) {
            prest.heure_fin = getTimeStamp(new Date());
            prest.str1 += coordinats.value.lat;
            prest.str2 += coordinats.value.long;
            if (prest.id === currentId.value) {
                currentId.value = null;
            }
        }
    }

    function uploadPrestation () {
        if (!navigator.onLine) {
            showSnackbar('L\'application n\'a pas de réseau internet...');
            return;
        }

        return new Promise((resolve, reject) => {
            closeCurrent();
            const localSend = sendData.value;

            localSend.forEach(prest => {
                prest.locked = true;
            });

            const listSize = localSend.length;

            sendingData.value = true;

            if (listSize === 0) {
                showSnackbar('Acune prestation present.', 'success');
                sendingData.value = false;
                resolve(true);
                return;
            }

            axios.post('module/timetracker/api/syncPrestations', localSend).then(() => {
                localSend.forEach(prest => {
                    prest.send = true;
                });
                showSnackbar('Les prestations ont été envoyées! (nbr: ' + listSize + ')', 'success');
                resolve(true);
            }).catch((err) => {
                showSnackbar('Erreur lors de l\'envoi des prestations! ('+err+').', 'error');
                reject(err);
            }).finally(() => {
                localSend.forEach(prest => {
                    prest.locked = false;
                });

                sendingData.value = false;
            });
        });
    }

    function handleTask (taskData) {
        if (current.value) {
            finishPrestation(taskData);
        } else {
            addPrestation(taskData);
        }
    }

    clearOldPrestations();

    return { handleTask, addPrestation, clearOldPrestations, current, currentId, finishPrestation, prestations, uploadPrestation, sendingData, closePrest };
});
