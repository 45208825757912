//COMMENT add comments
<template>
    <v-app style="height: 100vh;" id="inspire">
        <portal-target name="navigation" />
        <portal-target class="header-portal" name="header" />
        <router-view style="height: 0;" />
        <portal-target name="footer" />
        <div v-if="sendingData" class="overlay">
            <v-progress-circular
                indeterminate
                color="primary"
                size="150"
                width="10"
            ></v-progress-circular>
        </div>
        <Snackbar />
    </v-app>
</template>


<script setup>
import { storeToRefs } from 'pinia';
import { usePrestationStore } from './store/prestation';
import { useAppStore } from './store/app';
import Snackbar from './components/Common/Snackbar.vue';
import router from '@/router';


const { sendingData } = storeToRefs(usePrestationStore());
const { validConfig } = storeToRefs(useAppStore());

if (!validConfig.value) {
    router.push('/settings');
}

</script>

<style>
html,
body {
    overscroll-behavior-y: contain;
}

h2 {
    font-size: 2em !important;
    font-weight: 400 !important;
}

.rotate {
    animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.clickable {
    cursor: pointer;
}

.w-1\/4 {
    width: 25%;
}

.w-1\/2 {
    width: 50%;
}

.w-3\/4 {
    width: 75%;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.h-zero {
    height: 0%;
}

.bt-footer {
    border-top: 1px solid #e0e0e0 !important;
}

.bb-black {
    border-bottom: 1px solid #000 !important;
}

.bb-black-2 {
    border-bottom: 2px solid #000 !important;
}

.v-main__wrap {
    display: flex;
    flex-direction: column;
}

.v-application {
    height: 100vh;
}

.v-main {
    background-image: url('./assets/index.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.v-list {
    padding: 0 !important;
    background: rgba(0, 0, 0, 0) !important;
}

.header-portal::before {
    content: "";
    display: block;
    height: 12px;
    background: linear-gradient(to right, #CC6633, #B9131D, #781345, #442254);
}

.v-menu__content .v-list-item {
    background-color: #fff !important;
}

.select-none {
    user-select: none;
}

.render-limit.text-h4 {
    font-size: min(max(16px, 5vw), 2.125em) !important;
    line-height: normal;
}

.render-limit.text-h5 {
    font-size: min(max(16px, 5vw), 2.125em) !important;
    line-height: normal;
}

div.render-limit>h3 {
    font-size: min(max(16px, 5vw), 2.125em) !important;
}

@media (max-width: 550px) {

    .phone-col {
        flex-direction: column;
        width: auto !important;
    }
}

.render-limit h1 {
    font-size: min(max(16px, 5vw), 2em);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    width: 100vw;
    height: 100vh;

    background-color: #0005;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
